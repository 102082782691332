import { createGlobalStyle } from 'styled-components';
import { withPrefix } from 'gatsby'

const fontUrl = withPrefix('/assets/fonts')

const getFontFace = (family, style, weight, urlWithoutExtension) => {
  return `
    @font-face {
      font-family: '${family}';
      font-style: ${style};
      font-weight: ${weight};
      font-display: auto;
      src: url('${urlWithoutExtension}.eot');
      src: url('${urlWithoutExtension}.eot?#iefix') format('embedded-opentype'), url('${urlWithoutExtension}.woff2') format('woff2'), url('${urlWithoutExtension}.woff') format('woff'), url('${urlWithoutExtension}.ttf') format('truetype');
    }
  `
}

const GlobalStyle = createGlobalStyle`

  ${getFontFace('Proxima Nova', 'normal', 500, fontUrl + '/nova/proximaNova-regular')}
  ${getFontFace('Proxima Nova', 'normal', 700, fontUrl + '/nova/proximaNova-bold')}
  ${getFontFace('Proxima Nova', 'normal', 800, fontUrl + '/nova/proximaNova-extrabold')}


  ${getFontFace('Neue', 'normal', 300, fontUrl + '/neuehaas/NeueHaasDisplayLight')}
  ${getFontFace('Neue', 'normal', 500, fontUrl + '/neuehaas/NeueHaasDisplayMediu')}
  ${getFontFace('Neue', 'normal', 700, fontUrl + '/neuehaas/NeueHaasDisplayBold')}
  ${getFontFace('Neue', 'normal', 800, fontUrl + '/neuehaas/NeueHaasDisplayBlack')}
  
  ${getFontFace('OGG', 'normal', 300, fontUrl + '/ogg/OggLight')}
  ${getFontFace('OGG', 'normal', 500, fontUrl + '/ogg/OggRegular')}
  ${getFontFace('OGG', 'normal', 700, fontUrl + '/ogg/OggBold')}
  
`;
 
export default GlobalStyle;