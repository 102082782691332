import styled from 'styled-components'


export const HeaderContainer = styled.header`
    background: #fff;
    font-family: 'Proxima Nova';
    display: flex;
    padding: 0 15px;
    flex-direction: column;

    .header__title{
      font-size: 18px;
    }
    a{
      text-decoration:none;
      color: white;
      display: flex;
      align-items: center;
      align-content: center;
      flex-direction: column;
      margin-top: 10px;
    }
    svg{
      width: 30px;
      @media screen and (min-width: 1360px){
        width: 60px;
      }
    }
    
`
export const HeaderCopy = styled.div`
  font-family: 'Neue';
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 13px;
  position: relative;
  padding-bottom: 8px;
  @media screen and (min-width: 1360px){
    font-size: 20px;
    margin-top: 29px;
    padding-bottom: 20px;
  }
  &:after{
      content: '';
      width: 100%;
      height: 1px;
      background-color: #000;
      position: absolute;
      bottom: 0;
    }
`
export const Nav = styled.nav`
  width: 200px;
  color: white;
`

export const NavItems = styled.ul`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  list-style: none;
`

export const NavItem = styled.li`
  margin: 0;
`