import styled from "styled-components";

export const StageStyled= styled.div `
    padding: 0 15px;
    @media screen and (min-width: 1024px){
        margin: 25px auto;
        max-width: 784px;
    }
    @media screen and (min-width: 1360px){
        margin: 50px auto;
        max-width: 1046px;
    }

    @media screen and (min-width: 1920px){
        margin: 50px auto;
        max-width: 1360px;
    }
`
