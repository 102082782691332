import styled from 'styled-components'

export const FooterContainer = styled.footer`
  width: 100%;
  height: 100px;
  padding: 0 14.5%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Text = styled.div`
    font-family: 'Proxima Nova';
  p {
    font-size: 29px;
    letter-spacing: normal;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
  }
  @media screen and (min-width: 768px){
    p {
      font-size: 30px;
      letter-spacing: 1.05px;
    }
  }
`
