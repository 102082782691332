import React from "react"
import PropTypes from "prop-types"
import Stage from "../helpers/Stage"
import { HeaderContainer, HeaderCopy } from './styles'
import { LogoW } from '../svg/LogoW'
export const Header = ({ siteTitle }) => (
  <Stage>
    <HeaderContainer>
      
          <a href='http://grupow.com/' target='_blank' rel='noopener noreferrer' >
            <LogoW fill='#000'/>
          </a>
          <HeaderCopy>Grupo W</HeaderCopy>
      
        {/* <Nav>
          <NavItems>
            <NavItem><Link to="#">item1</Link></NavItem>
            <NavItem><Link to="#">item2</Link></NavItem>
            <NavItem><Link to="#">item3</Link></NavItem>
          </NavItems>
        </Nav> */}
    </HeaderContainer>
  </Stage>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
